import events from "events";

/**
 * Manage all media devices
 */
class MediaDevice extends events {
    /**
     * Start media devices and send stream
     */
    start(config) {
        // const constraints = {
        //     video: config.video,
        //     // video: {
        //     //     facingMode: 'user',
        //     //     height: {min: 360, ideal: 720, max: 1080}
        //     // },
        //     audio: config.audio
        // };
        const constraints = {
            video: true,
            // video: {
            //     facingMode: 'user',
            //     height: {min: 360, ideal: 720, max: 1080}
            // },
            audio: true
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.stream = stream;
                this.emit('stream', stream);
            })
            .catch((err) => {
                if (err instanceof DOMException) {
                    alert('Cannot open webcam and/or microphone');
                } else {
                    console.log(err);
                }
            });

        return this;
    }

    /**
     * Turn on/off a device
     * @param {String} type - Type of the device
     * @param {Boolean} [on] - State of the device
     */
    toggle(type, on) {
        const len = arguments.length;
        if (this.stream) {
            this.stream[`get${type}Tracks`]().forEach((track) => {
                track.enabled = len === 2 ? on : !track.enabled;
            });
        }
        return this;
    }

    /**
     * Stop all media track of devices
     */
    stop() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
        }
        return this;
    }
}

export default MediaDevice;
