import {useCallback, useEffect, useState} from "react";
import {APP_URL} from "../constants/server";

import useWs from './ws';
import usePc from './pc';
import useDc from './dc';


export const useConnection = (options) => {
    const [banner, setBanner] = useState(options.defBanner);
    const {pc, start: startPc, onOffer, onAnswer, onCandidate} = usePc();
    const {ws, start: startWs, stop: stopWs, sendOffer, sendCandidate} = useWs({onOffer, onAnswer, onCandidate, ns: options.ns})

    const onBanner = useCallback(({image}) => {
        setBanner(`${APP_URL}/banners${image}`);
    }, [setBanner])
    const {start: startDc} = useDc({onBanner});

    useEffect(() => {
        if (!ws) return
        startPc(sendOffer, sendCandidate);
    }, [ws, sendOffer, sendCandidate, startPc])

    useEffect(() => {
        if (!pc) return;
            startDc(pc)
    }, [pc, startDc])

    const start = useCallback(({sid, ns}) => {
        if (ws) return;
        startWs({sid, ns});
    }, [ws, startWs])

    const stop = useCallback(() => {
        if (!ws) return
        stopWs()
    }, [ws, stopWs])

    return {start, stop, banner}
}

