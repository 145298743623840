import React, {useCallback, useEffect} from "react";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from '@material-ui/core/Icon';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useBanner} from "../hooks/banner";
import VideoChat from './VideoChat';
import {getNamespace} from '../constants/appConfig';
import {APP_URL} from '../constants/server';
import CssBaseline from '@material-ui/core/CssBaseline';


const DEF_OPTIONS = { width: 290, height: 480, defBanner: `${APP_URL}/images/default.png`};

export default function Banner() {
    const ns = getNamespace();
    const options = Object.assign(DEF_OPTIONS, {ns});
    const [modal, setModal] = React.useState(null);
    const {banner, sendRequest, call, sendCall, sendEnd, start, sendMessage, setReadMessage} = useBanner(options);
    const [currentBanner, setCurrentBanner] = React.useState(banner);

    const onSelect = (event, type) => {
        start();
        setModal(type);
    }

    const handleClose = () => {
        setModal(null);
    };

    useEffect(() => {
        if (call.status === "end")
            setModal("text");
    }, [call, setModal])

    useEffect(() => {
        if (modal)
            return;
        setCurrentBanner(banner);
    }, [banner, modal])

    let initMode = 'text';
    if (modal === 'video')
        initMode = 'video'
    else if (modal === 'audio')
        initMode = 'audio'

    const countUnreadMessages = (call && call.messages && call.messages.filter(({type, isRead}) => type === "support" && !isRead).length) || 0

    const onSendRequest = useCallback((data) => {
        if (call.status === "new")
            return sendRequest({...data, src: currentBanner})
        sendRequest(data)
    }, [currentBanner, call, sendRequest]);

    return (<>
            <React.Fragment>
            <Container width={options.width} height={options.height} id="adjumps-banner-widget">
                {currentBanner ?
                    <img src={currentBanner} width={options.width} height={options.height}
                         alt={`Banner ns=${ns}`}/>
                    : <CircularProgress/>
                }
                <Bottom>
                    <BottomNav
                        onChange={onSelect}
                        showLabels>
                        <BottomNavigationAction label="Video Call" value="video" icon={<Icon>videocam</Icon>}/>
                        <BottomNavigationAction label="Audio Call" value="audio" icon={<Icon>call</Icon>}/>

                        <BottomNavigationAction label="Chat" value="text" icon={
                            <Badge badgeContent={countUnreadMessages} color="primary"
                                   invisible={countUnreadMessages <= 0}>
                                <Icon>chat</Icon>
                            </Badge>
                        }/>

                    </BottomNav>
                </Bottom>
            </Container>
                {modal && <Dialog aria-labelledby="simple-dialog-title" open={!!modal} onClose={handleClose}>
                    <CssBaseline />
                        <DialogTitleContainer id="simple-dialog-title">
                            <span>Chat with Agent</span>
                            <CloseButton onClick={handleClose}>
                                <Icon>close</Icon>
                            </CloseButton>
                        </DialogTitleContainer>
                        <VideoChat initMode={initMode}
                                   isCallerInit={true}
                                   call={{
                                       ...call,
                                       messages: call.messages ? call.messages.filter(({type}) => type !== "banner") : []
                                   }}
                                   sendRequest={onSendRequest}
                                   sendMessage={sendMessage}
                                   sendCall={sendCall}
                                   sendEnd={sendEnd}
                                   setReadMessage={setReadMessage}
                                   countUnreadMessages={countUnreadMessages}
                                   width="min(576px, calc(100vw - 64px))"
                                   height="min(380px, calc(100vh - 64px))"/>
                    </Dialog>}
            </React.Fragment>
        </>
    )
}

const BottomNav = styled(BottomNavigation)`
  background: rgba(255,255,255,0.8);
`
const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
`

const Container = styled.div`
  position: relative;
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  &:hover, &:active {
    ${Bottom} {
      display: block;
    }
  }
  max-width: 100%; 
  max-height: 100%; 
  object-fit: cover;
`

const DialogTitleContainer = styled(DialogTitle)`
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`


const CloseButton = styled(IconButton)`
  margin-right: -16px;
`
