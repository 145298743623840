import MediaDevice from './MediaDevice';
import events from "events";

import {TURN_SERVER, TURN_USERNAME, TURN_PASSWORD} from '../constants/server'

let turnConfig = {
    urls: TURN_SERVER,
}

if (TURN_USERNAME)
    turnConfig.username = TURN_USERNAME;

if (TURN_PASSWORD)
    turnConfig.credential = TURN_PASSWORD;

const PC_CONFIG = {
    iceServers: [{urls: "stun:stun.l.google.com:19302"}, turnConfig]
};

class PeerConnection extends events {
    /**
     * Create a PeerConnection.
     * @param {String} friendID - ID of the friend you want to call.
     */
    constructor({sendCall, sendEnd, sendRequest}) {
        super();
        this.sendRequest = sendRequest;
        this.sendCall = sendCall;
        this.sendEnd = sendEnd;
        this.pc = new RTCPeerConnection(PC_CONFIG);
        this.pc.onicecandidate = (event) => this.sendCall({
            candidate: event.candidate,
        });
        this.pc.ontrack = (event) => this.emit('peerStream', event.streams[0]);

        this.mediaDevice = new MediaDevice();
    }

    /**
     * Starting the call
     * @param {Boolean} isCaller
     * @param {Object} config - configuration for the call {audio: boolean, video: boolean}
     */
    start(isCaller, config) {
        this.mediaDevice
            .on('stream', (stream) => {
                stream.getTracks().forEach((track) => {
                    // track.enabled = config[track.kind];
                    if (this.pc)
                        this.pc.addTrack(track, stream);
                });
                this.emit('localStream', stream);
                if (isCaller)
                    this.sendRequest()
                else
                    this.createOffer();
            })
            .start(config);

        return this;
    }

    /**
     * Stop the call
     * @param {Boolean} isStarter
     */
    stop(isStarter) {
        if (isStarter) {
            this.sendEnd({});
        }
        this.mediaDevice.stop();
        this.pc.close();
        this.pc = null;
        this.removeAllListeners();
        return this;
    }

    createOffer() {
        this.pc.createOffer()
            .then(this.getDescription.bind(this))
            .catch((err) => console.log(err));
        return this;
    }

    createAnswer() {
        this.pc.createAnswer()
            .then(this.getDescription.bind(this))
            .catch((err) => console.log(err));
        return this;
    }

    getDescription(desc) {
        this.pc.setLocalDescription(desc);
        this.sendCall({sdp: desc})
        return this;
    }

    /**
     * @param {Object} sdp - Session description
     */
    setRemoteDescription(sdp) {
        const rtcSdp = new RTCSessionDescription(sdp);
        this.pc.setRemoteDescription(rtcSdp);
        return this;
    }

    /**
     * @param {Object} candidate - ICE Candidate
     */
    async addIceCandidate(candidate) {
        if (candidate) {
            const iceCandidate = new RTCIceCandidate(candidate);
            try {
                await this.pc.addIceCandidate(iceCandidate);
            } catch (e) {
                console.log(e)
            }
        }
        return this;
    }
}

export default PeerConnection;
