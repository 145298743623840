import React, {useRef, useEffect} from 'react';
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";

const Video = ({peerSrc, localSrc, config, mediaDevice, width, height, call}) => {
    const peerVideo = useRef(null);
    const localVideo = useRef(null);

    useEffect(() => {
        if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
        if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
    }, [peerSrc, localSrc]);

    useEffect(() => {
        if (mediaDevice) {
            mediaDevice.toggle('Video', config.video);
            mediaDevice.toggle('Audio', config.audio);
        }
    }, [config, mediaDevice]);
    const isVideoCall = call && call.config && call.config.video;

    return <Container isActive={isVideoCall}>
        <RemoteVideo ref={peerVideo} autoPlay playsInline width={width} height={height} isActive={isVideoCall}/>
        <LocalVideo ref={localVideo} autoPlay playsInline muted isActive={isVideoCall}/>
        <IconAudio isActive={!isVideoCall}/>
    </Container>
}


const Container = styled.div`
  position:relative;
  display: flex;
  justify-content: center;
  background:  ${({isActive}) => isActive ? 'black' : 'transparent'};
`

const IconAudio = styled(Icon).attrs({
    children: "volume_up"
})`
    font-size: 100px;
    position: absolute;
    color: black;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -106px;
    display: ${({isActive}) => isActive ? "block" : "none"};
    
`
const RemoteVideo = styled.video`
  max-width: ${({width}) => width || '100%'};
  max-height: ${({height}) => height || '100%'};
  background: black;
  padding-bottom: 56px;
  height: 100vh;
  opacity:  ${({isActive}) => isActive ? 1 : 0}
 
`
const LocalVideo = styled.video`
    width: 30%;
    position: absolute;
    top: 8px;
    left: 8px;
    background: black;
    opacity:  ${({isActive}) => isActive ? 1 : 0}
`

export default Video;
