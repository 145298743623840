import events from "events";

const WS_ACTIONS = {
    sendRequest: 'request',
    sendCall: 'call',
    sendEnd: 'end',
    sendMessage: 'chat:message'
};

const EVENTS = {
    request: 'request',
    call: 'call',
    end: 'end',
    bannerGet: 'banner:get',
    chatList: 'chat:list',
    message: 'chat:message',
};

export default class WsClient extends events {

    static getActions() {
        return WS_ACTIONS;
    }

    static getEvents() {
        return EVENTS;
    }

    constructor({ws_server, initParams}) {
        super();
        this.initParams = initParams;

        this.EVENTS = WsClient.getEvents();
        this.ACTIONS = WsClient.getActions();

        this.ws_server = ws_server;
        this.ws = null;
        this.reconnect_interval = 1000;
        this.destroyed = false;
        this.init();
    }

    getConnectionString() {

        const params = Object.keys(this.initParams).reduce((m, p) => {
            let rawVal = this.initParams[p];
            if (typeof rawVal === 'string' || typeof rawVal === "number")
                m += `${m ? '&' : '?'}${p}=${encodeURIComponent(rawVal)}`;
            return m;
        }, '');
        return this.ws_server + params;
    }

    init() {
        this.ws = new WebSocket(this.getConnectionString());
        this.ws.onopen = () => {
            console.log('<<<< WsClient connected >>>>');
            this.emit('connection:open', {});
        };
        this.ws.onmessage = evt => {
            console.log('<<<< WsClient data >>>>', evt.data);
            const data = JSON.parse(evt.data);
            if ('action' in data) {
                let action = data.action;
                delete data.action;
                this.emit(action, data);
            }
        };
        this.ws.onerror = e =>  console.log('<<<< WsClient error >>>>', e);
        this.ws.onclose = (e) => {
            if (this.destroyed)
                return;
            console.log('<<<< WsClient disconnected >>>>', e);
            this.emit('connection:close', e);
            if (this.reconnect_interval) {
                setTimeout(() => this.init(), this.reconnect_interval);
            }
        }
    }

    action(action, data) {
        if (!data) {
            data = {};
        }
        data.action = action;
        data = JSON.stringify(data);
        if (this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(data);
        }
    }

    destroy() {
        this.destroyed = true;
        this.ws.close();
        this.removeAllListeners();
    }
};
