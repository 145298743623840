import {useEffect, useState, useCallback} from "react";
import WsClient from "../libs/WsClient";
import {WS_SERVER_URL, APP_URL} from "../constants/server";
import {useLocalStorageState} from '@umijs/hooks';
import {useConnection} from "./connection";

const messageFile = `${APP_URL}/mp3/message.mp3`;
const newChatFile = `${APP_URL}/mp3/new-chat.mp3`;
const ringtoneFile = `${APP_URL}/mp3/ringtone.mp3`;
let ringtone = null;

export const useBanner = (options) => {
    const [sid, setSid] = useLocalStorageState('sid');
    const [ws, setWs] = useState(null);
    const [call, setCall] = useState({});

    const {start: startConnection, stop: stopConnection, banner} = useConnection(options)


    useEffect(() => {
        if (!sid) return
        startConnection({sid, ns: options.ns});
        return stopConnection
    }, [sid, startConnection, stopConnection])

    useEffect(() => {
        if (sid) return;
        setSid(Math.random());
    }, [sid, setSid]);

    const onMessage = useCallback(async (data) => {
        data.isRead = false;
        let _call = call || data;
        if (!_call.messages) _call.messages = [];
        _call.messages = [..._call.messages, data];
        _call.seq = call.seq ? call.seq + 1 : 1;
        setCall({..._call});
        if (data.type === "support")
            try {
                const a = new Audio(messageFile);
                await a.play();
            } catch (e) {
                console.log(e)
            }
    }, [call, setCall])

    const onRequest = useCallback(async (data) => {
        if (!data.config)
            try {
                const a = new Audio(newChatFile)
                await a.play()
            } catch (e) {
                console.log(e)
            }
        else {
            try {
                ringtone = new Audio(ringtoneFile);
                await ringtone.play();
            } catch (e) {
                console.log(e)
            }

        }

        setCall(call => {
            data.seq = call.seq ? call.seq + 1 : 1
            data.status = 'request';
            return {
                ...call, sdp: null,
                candidate: null,
                offer: null,
                answer: null, ...data
            };
        })
    }, [setCall])

    const onCall = useCallback((data) => {
        setCall(call => {
            data.seq = call.seq ? call.seq + 1 : 1
            data.status = 'call';
            data.config = data.config || call.config || null;
            return {
                ...call, sdp: null,
                candidate: null,
                offer: null,
                answer: null, ...data
            };
        });
    }, [setCall])

    const onEnd = useCallback((data) => {
        if (ringtone) {
            ringtone.pause();
            ringtone = null;
        }
        setCall(call => {
            data.seq = call.seq ? call.seq + 1 : 1
            data.status = 'end';
            return {
                ...call, sdp: null,
                candidate: null,
                offer: null,
                answer: null, ...data
            };
        });

    }, [setCall])


    useEffect(() => {
        const {ns} = options;
        const ws = new WsClient({ws_server: WS_SERVER_URL, initParams: {mode: "client", ns}});
        setWs(ws);
        return ws.destroy
    }, [options])


    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.message, onMessage)
        return () => ws.off(ws.EVENTS.message, onMessage)
    }, [ws, onMessage])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.request, onRequest)
        return () => ws.off(ws.EVENTS.request, onRequest)
    }, [ws, onRequest])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.call, onCall)
        return () => ws.off(ws.EVENTS.call, onCall)
    }, [ws, onCall])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.end, onEnd)
        return () => ws.off(ws.EVENTS.end, onEnd)
    }, [ws, onEnd])

    const sendRequest = useCallback((data) => {
        setCall(call => ({...call, config: data.config, status: "new-request", seq: call.seq ? call.seq + 1 : 1}));
        ws.action(WsClient.getActions().sendRequest, {...data, isOnline: true, status: "request"})
    }, [setCall, ws])

    const sendMessage = useCallback((data) => {
        ws.action(WsClient.getActions().sendMessage, {...data, isOnline: true});
    }, [ws])

    const sendCall = useCallback((data) => {
        if (ringtone) {
            ringtone.pause();
            ringtone = null;
        }

        setCall({...call, ...data, status: "call"});
        ws.action(WsClient.getActions().sendCall, {id: call.id, ...data, isOnline: true})
    }, [call, setCall, ws])

    const sendEnd = useCallback((data) => {
        if (ringtone) {
            ringtone.pause();
            ringtone = null;
        }
        setCall({
            ...call, sdp: null,
            candidate: null,
            offer: null,
            answer: null, status: "end", seq: call.seq ? call.seq + 1 : 1
        });
        ws.action(WsClient.getActions().sendEnd, {...call, ...data, status: "end"})
    }, [call, setCall, ws])

    const start = useCallback(() => {
        setCall(call => ({
            ...call,
            isOnline: true,
            sdp: null,
            candidate: null,
            offer: null,
            answer: null,
            status: "new",
            seq: call.seq ? call.seq + 1 : 1
        }));
    }, [setCall])

    const setReadMessage = useCallback(() => {
        setCall(call => ({
            ...call,
            messages: call.messages ? call.messages.map(message => ({...message, isRead: true})) : []
        }));
    }, [setCall])


    return {banner, call, sendRequest, sendCall, sendEnd, start, sendMessage, setReadMessage}
}
