import {useEffect, useCallback, useState} from "react";
import {SERVER_URL} from '../constants/server';


const getWSConnectionURl = (initParams) => {
    const params = Object.keys(initParams).reduce((m, p) => {
        let rawVal = initParams[p];
        if (typeof rawVal === 'string' || typeof rawVal === "number")
            m += `${m ? '&' : '?'}${p}=${encodeURIComponent(rawVal)}`;
        return m;
    }, '');
    return SERVER_URL + params;
}


export const useWs = ({onOffer, onAnswer, onCandidate, onBannersList, ns}) => {
    const [ws, setWs] = useState(null);


    const send = useCallback((action, data) => {
        if (!ws) return;
        if (!data) {
            data = {};
        }
        data.action = action;
        data = JSON.stringify(data);
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(data);
        }
    }, [ws]);

    const sendOffer = useCallback((data) => {
        send('offer', data)
    }, [send])

    const sendAnswer = useCallback((data) => {
        send('answer', data)
    }, [send])

    const sendCandidate = useCallback((data) => {
        send('candidate', data)
    }, [send])

    const sendUploadBanner  = useCallback(async ({file, url}) => {
        //wss.options.maxPayload: 104857600 //10 mb для баннера достаточно
        const MAX_CHUNK = 1024 * 1024 * 10;
        if (url.length > MAX_CHUNK)
            throw Error('Upload file size limit=' + MAX_CHUNK);
        const {name, size, type, lastModified} = file;
        await send('upload-banner', {
            name,
            size,
            type,
            lastModified,
            payload: url,
        });
    }, [send])

    const requestBannersList  = useCallback(async () => {
        await send('req-banners-list', {ns});
    }, [send])

    const deleteBanners  = useCallback(async (banners) => {
        await send('delete-banners', {ns, banners});
    }, [send])

    const onMessage = useCallback((event) => {
        const data = JSON.parse(event.data);
        if ('action' in data) {
            let action = data.action;
            delete data.action;
            switch (action) {
                case 'offer':
                    onOffer(data, sendAnswer);
                    break;
                case 'answer':
                    onAnswer(data);
                    break;
                case 'candidate':
                    onCandidate(data);
                    break;
                case 'banners-list':
                    onBannersList(data);
                    break;
                default:
            }
        }
    }, [onOffer, onAnswer, onCandidate, sendAnswer]);

    useEffect(() => {
        if (!ws) return;
        ws.addEventListener('message', onMessage)
        return () => ws.removeEventListener('message', onMessage)
    }, [ws, onMessage]);


    const start = useCallback((urlParams) => {
        if (ws) return
        const _ws = new WebSocket(getWSConnectionURl(urlParams));
        _ws.onopen = () => {
            setWs(_ws);
        }

    }, [ws])

    const stop = useCallback(() => {
        if (!ws) return;
        ws.close();
        setWs(null);

    }, [ws])

    return {ws, sendOffer, sendCandidate, sendUploadBanner, requestBannersList, deleteBanners, start, stop}
};

export default useWs;
