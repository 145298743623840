let CONFIG = {};
export const getConfig = (key) => key ? CONFIG[key] : {...CONFIG};
export const setConfig = (key, value) => CONFIG[key] = value;

export const getNamespace = () => {
    return CONFIG.NAMESPACE;
}

export const setNamespace = val => {
    CONFIG.NAMESPACE = val;
}

export const isWidget = val => typeof window.BannerWidget === 'function';
