import {useCallback, useState} from "react";

import {STUN_SERVER, TURN_SERVER, TURN_USERNAME, TURN_PASSWORD} from '../constants/server'

let turnConfig = {
    urls: TURN_SERVER,
}

if (TURN_USERNAME)
    turnConfig.username = TURN_USERNAME;

if (TURN_PASSWORD)
    turnConfig.credential = TURN_PASSWORD;

const PC_CONFIG = {
    // sdpSemantics: "unified-plan",
    iceServers: [
        // {urls: "stun:stun.l.google.com:19302"},
        {...turnConfig, urls: STUN_SERVER},
        turnConfig
    ]
};

console.log("PC_CONFIG", PC_CONFIG);

export const usePc = () => {
    const [pc, setPc] = useState(null);

    const onOffer = useCallback(async ({sdp}, sendAnswer) => {
        if (!pc) return;
        const rtcSdp = new RTCSessionDescription(sdp);
        await pc.setRemoteDescription(rtcSdp);

        const desc = await pc.createAnswer();
        await pc.setLocalDescription(desc);
        sendAnswer({sdp: desc});

    }, [pc]);

    const onAnswer = useCallback(async ({sdp}) => {
        if (!pc) return;

        const rtcSdp = new RTCSessionDescription(sdp);
        await pc.setRemoteDescription(rtcSdp);
    }, [pc]);

    const onCandidate = useCallback(async ({candidate}) => {
        if (!pc || !candidate) return;
        const iceCandidate = new RTCIceCandidate(candidate);
        await pc.addIceCandidate(iceCandidate);
    }, [pc]);


    const start = useCallback(async (sendOffer, sendCandidate) => {
        if (pc) return;
        const _pc = new RTCPeerConnection(PC_CONFIG);
        setPc(_pc)


        _pc.onicecandidate = (event) => {
            // console.log("onicecandidate", event.candidate);
            sendCandidate({
                candidate: event.candidate,
            })
        }

        // _pc.onicegatheringstatechange = (event) => {
        //     console.log("onicegatheringstatechange", _pc.iceConnectionState);
        // }

        _pc.onnegotiationneeded = () => {
            setTimeout(async () => {
                const desc = await _pc.createOffer();
                await _pc.setLocalDescription(desc);

                sendOffer({sdp: desc});
            }, 1000)
        }


    }, [pc, setPc])


    const stop = useCallback(() => {
        if (!pc) return
        pc.close();
        setPc(null)
    }, [pc, setPc])


    return {onOffer, onAnswer, onCandidate, pc, start, stop}
}


export default usePc;
