import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {ChatList} from 'react-chat-elements';
import {useSupport} from '../hooks/support'
import VideoChat from '../components/VideoChat'

const getIcon = ({config, status, messages}) => {
    const color = status === "request" || status === "new-request" || (messages && messages.filter(({type, isRead}) => type === "client" && !isRead).length) ? "primary" : "inherit";
    if (config && config.video)
        return <Icon color={color}>videocam</Icon>
    if (config && config.audio)
        return <Icon color={color}>call</Icon>
    return <Icon color={color}>chat</Icon>
}


const ChatItem = ({call, onBack, current, ...rest}) => {
    const isMobile = window.innerWidth < 600;
    if (!isMobile)
        return <ChatItemContainer isActive={call.id === current}>
            <VideoChat
                width={`calc(100vw - 288px)`}
                height={`calc(100vh - 48px - 64px)`}
                {...rest}
                callId={call.id}
                call={call}
                initConfig={null}
                countUnreadMessages={(call.messages && call.messages.filter(({type, isRead}) => type === "client" && !isRead).length) || 0}/>
        </ChatItemContainer>

    return <ChatItemContainer isActive={call.id === current}>
        <AppBar position="static" color="inherit">
            <Toolbar>
                <IconButton onClick={onBack}
                            edge="start"
                            color="inherit"
                            aria-label="open back">
                    <Icon>arrow_back</Icon>
                </IconButton>
                <Typography variant="h6">
                    {call.name}
                </Typography>
            </Toolbar>
        </AppBar>
        <VideoChat
            width={`calc(100vw)`}
            height={`calc(100vh - 64px - 50px)`}
            {...rest}
            callId={call.id}
            call={call}
            initConfig={null}
            countUnreadMessages={(call.messages && call.messages.filter(({type, isRead}) => type === "client" && !isRead).length) || 0}/>
    </ChatItemContainer>
}

export default function Client() {
    const {ns} = useParams();
    const {calls, sendRequest, sendCall, sendEnd, sendMessage, setReadMessage} = useSupport({ns});
    const [selected, setSelected] = useState([]);
    const [current, setCurrent] = useState(null);

    const onSendMessage = id => data => {
        sendMessage(id, data);
    }
    const onSendRequest = id => data => {
        sendRequest(id, data);
    }
    const onSendCall = id => data => {
        sendCall(id, data);
    }
    const onSendEnd = id => data => {
        sendEnd(id, data);
    }
    const onSetReadMessage = id => () => {
        setReadMessage(id);
    }

    const onSelect = ({id}) => {
        if (!selected.includes(id))
            setSelected(id);
        setCurrent(id);
    }

    const onBack = () => {
        setCurrent(null);
    }
    return (<>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">
                        Agent
                    </Typography>
                </Toolbar>
            </AppBar>
            <Layout>
                <List onClick={onSelect}
                      activeIndex={calls.findIndex(call => call.id === current) + 1}
                      dataSource={calls.map(call => ({
                          avatar: `https://avatar.ok.sb/?text=${call.id}&size=150`,
                          id: call.id,
                          title: call.name,
                          subtitle: getIcon(call),
                          date: new Date(call.date),
                          statusColor: call.isOnline ? '#3f51b5' : 'transparent',
                          statusColorType: 'encircle',
                          unread: (call.messages && call.messages.filter(({type, isRead}) => type === "client" && !isRead).length) || 0,
                      }))}
                />
                {calls.filter(({id}) => selected.includes(id)).map(call => (
                    <ChatItem key={call.id}
                              call={call}
                              current={current}
                              sendRequest={onSendRequest(call.id)}
                              sendMessage={onSendMessage(call.id)}
                              setReadMessage={onSetReadMessage(call.id)}
                              sendCall={onSendCall(call.id)}
                              sendEnd={onSendEnd(call.id)}
                              onBack={onBack}/>

                ))}
            </Layout>
        </>
    )
}

const Layout = styled(Paper)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 0;
    display: block;
  }
`
const List = styled(ChatList)`
  border: 1px solid #eee;
  max-height: calc(100vh - 64px - 24px - 24px);
 
   @media (max-width: 600px) {
    max-height: calc(100vh - 24px - 24px);
    width: 100vw;
    ${({activeIndex}) => activeIndex && "display: none;"}
  }
  .rce-container-citem:nth-child(${({activeIndex}) => activeIndex}) {
      .rce-citem {
        background: #eee;
      }
  }
`

const ChatItemContainer = styled.div`
  display: ${({isActive}) => isActive ? 'block' : 'none'};
`;
