import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';

import Client from './pages/client';
import Support from './pages/support';
import AdmBanners from './pages/admBanners';

export default function App() {
    return (<>
            <CssBaseline/>
            <Router>
                <Switch>
                    <Route key="client" exact path="/:ns">
                        <Client/>
                    </Route>
                    <Route key="client" exact path="/:ns/agent">
                        <Support/>
                    </Route>
                    <Route key="client" exact path="/:ns/settings">
                        <AdmBanners/>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

