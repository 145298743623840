import React from 'react';
import {useParams} from "react-router-dom";
import {setNamespace} from '../constants/appConfig';
import Banner from '../components/Banner'

export default function Client() {
    setNamespace(useParams().ns);
    return (<>
            <div className="row">
                <div className="col-md-12 col-lg-2"></div>
                <div className="col-md-12 col-lg-8 main-block">
                    <div className="row">
                        <div className="col-md-12 col-lg-1"></div>
                        <div className="col-md-12 col-lg-10">
                            <div className="row content">
                                <div className="col content">

                                    <div className="row">
                                        <div className="col">
                                            <div id="logo"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col info-block">
                                            <p className="info-block-top">
                                                AdJumps shows advertisements &
                                                <br/>
                                                maintains the dialogue with the visitor on your website.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-8 info-block">
                                            <p>
                                                Your website value increases as visitors stay engaged while considering
                                                advertisements.
                                            </p>
                                            <p className="media">
                                                Advertisers gain the benefit of better lead qualification using available
                                                multimedia methods (text, audio, video).
                                            </p>
                                        </div>

                                        <div className="col-md-12 col-lg-4">
                                            <div id="widget">
                                                <Banner />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col info-block">
                                            <p>
                                                <br/>
                                                Visitors stay on your website after engaging with advertisements.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-1"></div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-2"></div>
                </div>
            </div>
        </>
    )
}
