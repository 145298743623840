
export const APP_URL = window.APP_URL || process.env.APP_URL || '';
export const SERVER_URL = window.SERVER_URL || process.env.SERVER_URL || 'ws://localhost:3001';
export const WS_SERVER_URL = window.WS_SERVER_URL || process.env.WS_SERVER_URL || 'ws://localhost:3002';

export const STUN_SERVER = window.STUN_SERVER || process.env.STUN_SERVER || 'stun:stun.l.google.com:19302';

export const TURN_SERVER = window.TURN_SERVER || process.env.TURN_SERVER || 'stun:stun.l.google.com:19302';
export const TURN_USERNAME = window.TURN_USERNAME || process.env.TURN_USERNAME || null;
export const TURN_PASSWORD = window.TURN_PASSWORD || process.env.TURN_PASSWORD || null;


// export const SERVER_URL = window.SERVER_URL || 'ws://192.168.31.73:3001';
// export const WS_SERVER_URL = window.WS_SERVER_URL || 'ws://192.168.31.73:3002';
//
// export const STUN_SERVER = window.STUN_SERVER || 'stun:192.168.31.73:3478';
//
// export const TURN_SERVER = window.TURN_SERVER || 'turn:192.168.31.73:3478';
// export const TURN_USERNAME = window.TURN_USERNAME || 'username';
// export const TURN_PASSWORD = window.TURN_PASSWORD || 'password';
