import {useEffect, useCallback, useState} from "react";


export const useDc = ({onBanner}) => {
    const [dc, setDc] = useState(null);

    const start = useCallback(async (pc) => {
        if (dc) return;
        const _dc = await pc.createDataChannel('data-channel');
        setDc(_dc)

        // pc.addEventListener('datachannel', ({channel})=>{
        //     if (channel.label !== 'data-channel') {
        //         return;
        //     }
        //     setDc(channel)
        // });
    }, [dc, setDc])

    const stop = useCallback(() => {
        setDc(null);
    }, [setDc])

    const onMessage = useCallback((event) => {
        const data = JSON.parse(event.data);
        if ('action' in data) {
            let action = data.action;
            delete data.action;
            switch (action) {
                case 'banner':
                    onBanner(data);
                    break;
                default:
            }
        }
    }, [onBanner]);


    useEffect(() => {
        if (!dc) return;
        dc.addEventListener('message', onMessage);

        return () => dc && dc.removeEventListener('message', onMessage)
    }, [dc, onMessage])


    return {start, stop}
}

export default useDc;
