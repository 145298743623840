import React, {useEffect, useState, useRef} from "react";

import Call from "./Call";
import Chat from "./Chat";

import styled from "styled-components";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Icon from "@material-ui/core/Icon";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Badge from '@material-ui/core/Badge';


function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const VideoChat = ({initMode, width, height, call, isCallerInit, sendRequest, sendCall, sendEnd, sendMessage, setReadMessage, countUnreadMessages}) => {
    let initConfig = null;
    if (initMode === 'video' || initMode === 'audio') {
        initConfig = {
            video: initMode === 'video',
            audio: initMode === 'video' || initMode === 'audio',
            text: false
        }
    }
    const [config, setConfig] = useState(initConfig);
    const [isCaller, setCaller] = React.useState(isCallerInit);

    const prevCall = usePrevious(call);

    const onSelect = (event, newTab) => {
        if (newTab === 'cancel') {
            setCaller(false);
            setConfig(null);
            return sendEnd();
        }


        if (newTab === 'video-answer' || newTab === 'audio-answer') {
            setCaller(false);
            return setConfig(call.config);
        }
        if (newTab === 'video-call' || newTab === 'audio-call') {
            setCaller(true);
            const config = {
                video: newTab === 'video-call',
                audio: newTab === 'video-call' || newTab === 'audio-call',
                text: false
            };
            return setConfig(config);
        }

        setConfig(config => {
            const newConfig = {...config}
            newConfig[newTab] = !newConfig[newTab];
            return newConfig;
        });
    }
    useEffect(() => {
        if (config && config.video && call && call.config && !call.config.video) {
            console.log("useEffect", config && config.video && call && call.config && !call.config.video, config, call);
            return sendCall({...call, config});
        }
    }, [config, call, sendCall])
    useEffect(() => {
        if ((!prevCall || prevCall.status !== "end") && call.status === "end" && config) {
            setCaller(false);
            setConfig(null);
        }

    }, [call, prevCall, config, setConfig])

    return (
        <Container width={width} height={height}>

            <ContainerElement width={width} height={height}>
                <Call isCaller={isCaller}
                      call={call}
                      width={width}
                      height={height}
                      config={config}
                      sendRequest={sendRequest}
                      sendCall={sendCall}
                      sendEnd={sendEnd}/>
            </ContainerElement>
            {(!config || config.text) && (<ContainerElement>
                <Chat width={width} height={height} sendMessage={sendMessage} messages={call.messages || []}
                      setReadMessage={setReadMessage}/>
            </ContainerElement>)}
            <Bottom>
                <BottomNavigation
                    value={(!config || config.text) ? 'text' : null}
                    onChange={onSelect}
                    showLabels>
                    {call.status === "request" && call.config && call.config.video && (
                        <BottomNavigationAction label="Video Answer" value="video-answer" icon={<Icon>videocam</Icon>}/>
                    )}
                    {call.status === "request" && call.config && !call.config.video && call.config.audio && (
                        <BottomNavigationAction label="Audio Answer" value="audio-answer" icon={<Icon>call</Icon>}/>
                    )}


                    {(!call || call.status === "end" || !call.config) && (
                        <BottomNavigationAction label="Video Call" value="video-call" icon={<Icon>videocam</Icon>}/>
                    )}
                    {(!call || call.status === "end" || !call.config) && (
                        <BottomNavigationAction label="Audio Call" value="audio-call" icon={<Icon>call</Icon>}/>
                    )}


                    {(call.status === "call" || call.status === "new-request") && (
                        <BottomNavigationAction label="Camera" value="video"
                                                icon={
                                                    <Icon>{config && config.video ? "videocam" : "videocam_off"}</Icon>}/>
                    )}
                    {(call.status === "call" || call.status === "new-request") && (
                        <BottomNavigationAction label="Microphone" value="audio"
                                                icon={<Icon>{config && config.audio ? "mic" : "mic_off"}</Icon>}/>
                    )}
                    {(call.status === "call" || call.status === "new-request") && call.config && (call.config.video || call.config.audio) &&
                    <BottomNavigationAction label="Cancel" value="cancel" icon={<Icon>cancel</Icon>}/>}


                    {config && <BottomNavigationAction label="Chat" value="text" icon={
                        <Badge badgeContent={countUnreadMessages} color="primary"
                               invisible={countUnreadMessages <= 0}>
                            <Icon>chat</Icon>
                        </Badge>
                    }/>}


                </BottomNavigation>
            </Bottom>
        </Container>
    )

}

export default VideoChat


const Container = styled.div`
  position: relative;
  width: ${({width}) => width ? width : '600px'};
  height: ${({height}) => height ? height : '400px'};
`

const ContainerElement = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 64px;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`
