import {useEffect, useState, useCallback} from "react";
import WsClient from "../libs/WsClient";
import {APP_URL, WS_SERVER_URL} from "../constants/server";

const messageFile = `${APP_URL}/mp3/message.mp3`;
const newChatFile = `${APP_URL}/mp3/new-chat.mp3`;
const ringtoneFile = `${APP_URL}/mp3/ringtone.mp3`;
const audios = {};

export const useSupport = (options) => {
    const [calls, setCalls] = useState([]);
    const [ws, setWs] = useState(null);

    const onChatList = useCallback(({chats}) => {
        setCalls(chats.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()).map(chat => {
                let date = localStorage.getItem(`chat:${chat.id}:lastDateTimeRead`);
                if (date)
                    date = (new Date(date)).getTime()

                return {
                    ...chat,
                    messages: (chat.messages || []).map(message => ({
                        ...message,
                        isRead: date && (new Date(message.date)).getTime() <= date
                    })),
                    seq: 1
                }
            }
        ))
    }, [setCalls])


    const onMessage = useCallback(async (data) => {
        data.isRead = false;

        if (!calls.find(call => call.id === data.id))
            return setCalls([{
                id: data.id,
                seq: 1,
                messages: [data],
                date: data.date
            }, ...calls])

        setCalls(calls.map(call => call.id === data.id ? {
                ...call,
                sdp: null,
                candidate: null,
                offer: null,
                answer: null,
                seq: call.seq ? call.seq + 1 : 1,
                messages: call.messages ? [...call.messages, data] : [data],
                date: data.date
            }
            : call
        ).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))

        if (data.type === "client")
            try {
                const a = new Audio(messageFile)
                await a.play()
            } catch (e) {
                console.log(e)
            }

    }, [calls, setCalls])

    const onRequest = useCallback(async (data) => {
        if (!data.config)

            try {
                const a = new Audio(newChatFile);
                await a.play()
            } catch (e) {
                console.log(e)
            }
        else {
            try {
                audios[data.id] = new Audio(ringtoneFile);
                await audios[data.id].play();
            } catch (e) {
                console.log(e)
            }

        }

        if (!calls.find(call => call.id === data.id))
            return setCalls([{
                ...data,
                seq: 1,
                status: "request",
            }, ...calls])

        setCalls(calls.map(call => call.id === data.id ? {
                ...call,
                sdp: null,
                candidate: null,
                offer: null,
                answer: null,
                ...data,
                seq: call.seq ? call.seq + 1 : 1,
                status: "request",
            }
            : call
        ).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))


    }, [calls, setCalls])

    const onCall = useCallback((data) => {
        setCalls(calls
            .map(call => call.id === data.id ? {
                    ...call,
                    sdp: null,
                    candidate: null,
                    offer: null,
                    answer: null,
                    ...data,
                    seq: call.seq ? call.seq + 1 : 1,
                    config: data.config || call.config || null,
                    status: "call",
                }
                : call
            ).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))
    }, [calls, setCalls])

    const onEnd = useCallback((data) => {
        if (audios[data.id]) {
            audios[data.id].pause();
            delete audios[data.id];
        }

        setCalls(calls.map(call => call.id === data.id ? {
            ...call,
            sdp: null,
            candidate: null,
            offer: null,
            answer: null,
            ...data,
            seq: call.seq ? call.seq + 1 : 1,
            status: "end",

        } : call).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))
    }, [calls, setCalls])


    useEffect(() => {
        const {ns} = options||{};
        const ws = new WsClient({ws_server: WS_SERVER_URL, initParams: {mode: "support", ns}});
        setWs(ws);
        return ws.destroy
    }, [])


    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.chatList, onChatList)
        return () => ws.off(ws.EVENTS.chatList, onChatList)
    }, [ws, onChatList])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.message, onMessage)
        return () => ws.off(ws.EVENTS.message, onMessage)
    }, [ws, onMessage])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.request, onRequest)
        return () => ws.off(ws.EVENTS.request, onRequest)
    }, [ws, onRequest])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.call, onCall)
        return () => ws.off(ws.EVENTS.call, onCall)
    }, [ws, onCall])

    useEffect(() => {
        if (!ws) return
        ws.on(ws.EVENTS.end, onEnd)
        return () => ws.off(ws.EVENTS.end, onEnd)
    }, [ws, onEnd])

    const sendRequest = useCallback((id, data) => {
        setCalls(calls.map(call => call.id === id ? {
            ...call,
            config: data.config,
            seq: call.seq ? call.seq + 1 : 1,
            status: "new-request",
        } : call).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))

        ws.action(WsClient.getActions().sendRequest, {id, ...data, status: "request"})
    }, [calls, setCalls, ws])

    const sendMessage = useCallback((id, data) => {
        ws.action(WsClient.getActions().sendMessage, {id, ...data})
    }, [ws])

    const sendCall = useCallback((id, data) => {
        if (audios[id]) {
            audios[id].pause();
            delete audios[id];
        }


        setCalls(calls.map(call => call.id === id ? {
            ...call,
            ...data,
            status: "call",
        } : call).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))
        ws.action(WsClient.getActions().sendCall, {id, ...data})
    }, [calls, setCalls, ws])

    const sendEnd = useCallback((id, data) => {
        if (audios[id]) {
            audios[id].pause();
            delete audios[id];
        }

        setCalls(calls.map(call => call.id === id ? {
            ...call,
            seq: call.seq ? call.seq + 1 : 1,
            sdp: null, candidate: null,
            status: "end",
            config: {video: false, audio: false, text: true},
        } : call).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))
        ws.action(WsClient.getActions().sendEnd, {id, ...data, status: "end"})
    }, [calls, setCalls, ws])

    const setReadMessage = useCallback((id) => {
        const call = calls.find(call => call.id === id);
        if (call && call.messages && call.messages.length)
            localStorage.setItem(`chat:${id}:lastDateTimeRead`, call.messages[call.messages.length - 1].date);

        setCalls(calls.map(call => call.id === id ? {
            ...call,
            messages: call.messages ? call.messages.map(message => ({...message, isRead: true})) : []
        } : call).sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()))
    }, [calls, setCalls])

    return {calls, sendCall, sendEnd, sendRequest, sendMessage, setReadMessage}
}
