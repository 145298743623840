import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import {useAdmBanners} from '../hooks/admBanners';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 480,
    },
    blockList: {
        width: '50%',
        padding: '10px 40px 10px 80px',
    },
    blockForm: {
        width: '50%',
        padding: '10px 40px 10px 80px',
    },
    imageWrapper: {
        height: 240,
        width: '100%'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
}));

export default function Client() {
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const {ns} = useParams();
    const {banners, filesRead, readInputFiles, submitBanner, deleteBanners} = useAdmBanners({ns});
    const onFileSelect = e => {
        readInputFiles(e.target.files);
    }
    const onFormSubmit = async e => {
        e.preventDefault();
        await submitBanner();
        setFileInputKey(Date.now());
        return false;
    };
    const onClickDelete = file => e => deleteBanners([file]);
    const classes = useStyles();
    return (<>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">
                        Banners of namespace: "{ns}"
                    </Typography>
                </Toolbar>
            </AppBar>
            <Layout>
                <div className={classes.blockList}>
                    <List dense >
                        {banners.map((value) => {
                            return (
                                <ListItem key={value} button>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            action={
                                                <Button  size="small" color="secondary" onClick={onClickDelete(value)} >
                                                    Delete
                                                </Button>
                                            }
                                            subheader={value}
                                        />
                                        <CardContent>
                                            <div className={classes.imageWrapper}>
                                                <img src={`/banners${value}`} className={classes.image} />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>

                <div className={classes.blockForm}>
                    <form onSubmit={onFormSubmit}>
                        <div className="form-group">
                            {/* preview */}
                            {Array.isArray(filesRead) && filesRead.map((fl, i) => {
                                    return <div key={i} className={classes.imageWrapper}>
                                        <img src={fl.url} height={'100%'} />
                                    </div>
                                }
                            )}
                            <div>
                                <input type="file"
                                       className="form-control-file"
                                       name="banner-file"
                                       onChange={onFileSelect}
                                       key={fileInputKey}
                                />
                            </div>
                            {Array.isArray(filesRead) && filesRead.length ?
                                <div>
                                    <input type="submit" value="Add banner" className="btn btn-default"/>
                                </div>
                                : null
                            }
                        </div>
                    </form>
                </div>
            </Layout>
        </>
    )
}

const Layout = styled(Paper)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 0;
    display: block;
  }
`

