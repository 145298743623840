import React, {Component} from "react";

import Video from "./Video";

import PeerConnection from "../libs/PeerConnection";

class Call extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localSrc: null,
            peerSrc: null,
            config: null
        };
        this.pc = {};
        this.sendRequest = this.sendRequest.bind(this)
        this.sendCall = this.sendCall.bind(this)
        this.sendEnd = this.sendEnd.bind(this)
    }

    componentDidMount() {
        const {isCaller, config} = this.props;
        if (isCaller) {
            if (config)
                this.startCall(config)
            else
                this.sendRequest(config)
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        const {localSrc, peerSrc} = this.state;
        const {isCaller, config, call} = this.props;
        return localSrc !== nextState.localSrc
            || peerSrc !== nextState.peerSrc
            || isCaller !== nextProps.isCaller
            || config !== nextProps.config
            || call !== nextProps.call
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {call, config} = this.props;

        if (!this.state.config && config) {
            return this.startCall(config)
        }


        if (this.state.config && call.status === 'end') {
            return this.endCall()
        }

        if (config && config.video !== prevProps.config.video && this.pc.mediaDevice) {
            this.pc.mediaDevice.toggle('Video', config.video)
        }
        if (config && config.audio !== prevProps.config.audio && this.pc.mediaDevice) {
            this.pc.mediaDevice.toggle('Audio', config.audio)
        }

        if (call.seq === prevProps.call.seq)
            return false

        if (call.sdp) {
            this.pc.setRemoteDescription(call.sdp);
            if (call.sdp.type === 'offer') this.pc.createAnswer();
        } else if (call.candidate) {
            this.pc.addIceCandidate(call.candidate);
        }
    }

    componentWillUnmount() {
        this.endCall(true)
    }

    startCall(config) {
        const {isCaller} = this.props;
        this.setState({config})
        this.pc = new PeerConnection({sendRequest: this.sendRequest, sendCall: this.sendCall, sendEnd: this.sendEnd})
            .on('localStream', (src) => {
                this.setState({localSrc: src});
            })
            .on('peerStream', (src) => this.setState({peerSrc: src}))
            .start(isCaller, config);
    }

    endCall(isStarter) {
        if (this.pc.stop) {
            this.pc.stop(isStarter);
        }
        this.pc = {};
        this.setState({
            localSrc: null,
            peerSrc: null,
            config:null
        });
    }

    sendRequest() {
        this.props.sendRequest({config: this.state.config})
    }

    sendCall(data) {
        this.props.sendCall(data)
    }

    sendEnd(data) {
        this.props.sendEnd(data)
    }

    render() {
        const {localSrc, peerSrc} = this.state;
        const {width, height, call, config} = this.props;
        if (!config) return null;
        return <Video localSrc={localSrc}
                      peerSrc={peerSrc}
                      config={config}
                      call={call}
                      width={width}
                      height={height}
                      mediaDevice={this.pc.mediaDevice}/>


    }
}

export default Call;

