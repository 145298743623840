import {useEffect, useState, useCallback} from "react";
import useWs from './ws';

export const useAdmBanners = (options) => {
    const { ns } = options;
    const [banners, setBanners] = useState([]);
    const [filesRead, setFilesRead] = useState([]);
    const onBannersList = ({banners}) => setBanners(banners);
    const {ws, start: startWs, stop: stopWs, sendUploadBanner, requestBannersList, deleteBanners} = useWs({onBannersList, ns: options.ns})

    useEffect(() => {
        if (!ns || !ws) return;
        requestBannersList();
        return stopConnection
    }, [ns, ws])

    const submitBanner = async () => {
        await filesRead.map( async ({file, url})=>await sendUploadBanner({file, url}));
        setFilesRead([]);
    };

    /**
     * Read base64 from input file
     * @param file
     * @return {Promise<unknown>}
     */
    const flReader = async (file) => {
        const reader = new FileReader();
        return await new Promise(resolve => {
            reader.onloadend = () => {
                resolve({
                    url: reader.result,
                    file: file
                });
            };
            reader.readAsDataURL(file);
        });
    }
    /**
     * Read input files
     * @param inpFiles
     * @return {Promise<void>}
     */
    const readInputFiles = async (inpFiles) => {
        inpFiles = !inpFiles[0] ? [] : inpFiles;
        let frp = Object.keys(inpFiles)
            .map((key) => !isNaN(key) ? flReader(inpFiles[key]) : null)
            .filter(v=>!!v);
        const flRead = await Promise.all(frp);
        setFilesRead(flRead);
    };

    const startConnection = useCallback(({ns}) => {
        if (ws) return;
        startWs({ns});
    }, [ws, startWs]);
    const stopConnection = useCallback(() => {
        if (!ws) return
        stopWs()
    }, [ws, stopWs]);
    useEffect(() => {
        startConnection({ns});
        return stopConnection
    }, [ns, startConnection, stopConnection])

    return {
        banners,
        filesRead,
        readInputFiles,
        submitBanner,
        deleteBanners,
    };
}
