import React, {useState, useCallback, useRef} from 'react'
import styled from "styled-components";
import {MessageList, Input, Button} from 'react-chat-elements';


const Chat = ({width, height, sendMessage, messages, setReadMessage}) => {
    let inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const addMessage = useCallback(() => {

        if (!inputValue)
            return
        inputRef.current.clear();
        setInputValue("")
        sendMessage({text: inputValue})
    }, [inputValue, inputRef, sendMessage, setInputValue]);


    const onKeyPress = useCallback((e) => {
        if (e.charCode === 13)
            addMessage()
    }, [addMessage])

    const onChange = (event) => {
        setInputValue(event.target.value)
    }
    const onFocus = (event) => {
        if (messages.find(({isRead}) => !isRead))
            setReadMessage();
    }

    return <Container width={width} height={height}>

        <List lockable
              height={height}
              dataSource={
                  messages.map(({date, text, type, id, data}) => ({
                      date: new Date(date),
                      text,
                      type: type === "banner" ? 'photo': 'text',
                      data,
                      avatar: `https://avatar.ok.sb/?text=${type === "support" ? "agent" : id}&size=150`,
                      position: type === "support" ? 'left' : 'right',
                  }))

              }
        />

        <InputStyle
            placeholder="Type here..."
            ref={inputRef}
            onKeyPress={onKeyPress}
            onChange={onChange}
            onFocus={onFocus}
            rightButtons={
                <Button text='Send' onClick={addMessage}/>
            }/>
    </Container>
}

export default Chat


const Container = styled.div`
  background: #eee;
  padding: 8px;
  width: ${({width}) => width};
  height: ${({height}) => height};
`
const List = styled(MessageList)`
   overflow-y: auto;
   height: 100%;
   padding-bottom: 90px;
`
const InputStyle = styled(Input)`
  position: absolute;
  bottom: -8px;
  left: 0;
  min-width: calc(100%);
  border: 1px solid #eee;
`
